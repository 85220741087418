'use client'
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "@/components/common/ui/Img";
import Button from "@/components/common/ui/Button";
import {white} from "@/styles/globalStyleVars";

const ErrorPage = () => {

    return (
        <StyledErrorPage className='StyledErrorPage'>
            <Img alt={'Error Page'} src={"/images/static/404.png"}/>
            <Container>
                <Row>
                    <Col sm={{span: 12}} className={'d-flex align-items-center justify-content-center flex-column'}>
                        <h2>Oops! <br/>
                            Page Not Found.</h2>
                        <p>The page you're looking for seems to have disappeared into the digital <br/> abyss. But don't worry, we're here to help you get back on track.</p>
                        <Button margin={'40px 0 0 0'} marginSm={"30px 0 0 0"} src={"/"} text="Return Home"/>
                    </Col>
                </Row>
            </Container>
        </StyledErrorPage>
    );
};

const StyledErrorPage = styled.div`
    background: #e9e9e9;
    position: relative;
    padding: 225px 0;
    min-height: ${`100svh`};
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
        color: ${white};
        margin-bottom: 40px;
    }

    p {
        color: ${white};
    }


    @media (max-width: 991px) {
        .col-sm-8 {
            min-width: 100%;
            margin: 0;
        }
    }
    @media (max-width: 600px) {
        h1 {
            font-size: 80px;
            line-height: 80px;
        }
    }
`;
export default ErrorPage;
